@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding-top: 1px;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
}

.App {
  width: 100%;
  height: 100vh;
}

.container{
  position: relative;
}

.searchInputs {
  margin-top: 105px;
  display: flex;
}

.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  height: 30px;
  width: 80%;
  margin:  0px auto 0px auto;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 5px;
  width: 100%;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}
a:link { text-decoration: none; }

#clearBtn {
  cursor: pointer;
}
