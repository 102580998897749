@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding-top: 1px;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
}

.App {
  width: 100%;
  height: 100vh;
}

.container{
  position: relative;
}

.searchInputs {
  margin-top: 105px;
  display: flex;
}

.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  height: 30px;
  width: 80%;
  margin:  0px auto 0px auto;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 5px;
  width: 100%;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}
a:link { text-decoration: none; }

#clearBtn {
  cursor: pointer;
}

.fundo{
  -webkit-animation: scales 3s alternate  infinite;
          animation: scales 3s alternate  infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.pao-baixo{
   -webkit-animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
           animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.pao-cima{
   -webkit-animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
           animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
}
.olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
   -webkit-transform-origin: center;
           transform-origin: center;
}

.left-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
      -webkit-transform-origin: 150px 156px;
              transform-origin: 150px 156px;
}

.right-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
      -webkit-transform-origin: 310px 150px;
              transform-origin: 310px 150px;
}

.olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
   -webkit-transform-origin: center;
           transform-origin: center;
}
@-webkit-keyframes scales{
  from { -webkit-transform: scale(0.98); transform: scale(0.98)}
  to{ -webkit-transform: scale(1); transform: scale(1)}
}
@keyframes scales{
  from { -webkit-transform: scale(0.98); transform: scale(0.98)}
  to{ -webkit-transform: scale(1); transform: scale(1)}
}

@-webkit-keyframes rotatepao{
  0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
  50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
  100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
 
}

@keyframes rotatepao{
  0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
  50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
  100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
 
}

@-webkit-keyframes olhos{
  0%{
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
   100%{
    -webkit-transform: rotateX(30deg);
            transform: rotateX(30deg);
  }
}

@keyframes olhos{
  0%{
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
   100%{
    -webkit-transform: rotateX(30deg);
            transform: rotateX(30deg);
  }
}

@-webkit-keyframes left-sparks{
  0%{
    opacity: 0; 
  }
  
}

@keyframes left-sparks{
  0%{
    opacity: 0; 
  }
  
}


.main{
  
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  -webkit-animation: dash 4s   alternate infinite;
          animation: dash 4s   alternate infinite;
}

@-webkit-keyframes dash{
   0%, 30%{
     fill: 4B4B62;
    stroke-dashoffset:   0;
  }
   80%,100%{
     fill: transparent;
    stroke-dashoffset:  -200;
  }
   
  
}

@keyframes dash{
   0%, 30%{
     fill: 4B4B62;
    stroke-dashoffset:   0;
  }
   80%,100%{
     fill: transparent;
    stroke-dashoffset:  -200;
  }
   
  
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #5101d1;
  border-color: #5101d1 transparent #5101d1 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.header {
  background-image: url('https://res.cloudinary.com/dh6j2zoog/image/upload/v1655702025/CiS/bg_jhdw6s.png');
  height: 550px;
  background-size: 150vw;
  background-repeat: no-repeat;
  position: relative;
}

.header button {
  color: #fff;
  background-color: #f29422;
  font-size: 18px;
  border-radius: 30px;
  padding: 20px;
  border: none;
  margin-top: 20em;
  margin-left: calc(((100% - 270px) / 3) * 2);
}

.header button:hover {
  background-color: #3d9dd9;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

@media screen and (max-width: 1162px) {
  .header button {
    margin-top: 15em;
    font-size: 16px;
  }
  .header {
    height: 400px;
  }
}

@media screen and (max-width: 880px) {
  .header button {
    margin-top: 12em;
    font-size: 16px;
  }
  .header {
    height: 300px;
  }
}

@media screen and (max-width: 695px) {
  .header button {
    margin-top: 10em;
    font-size: 16px;
  }
  .header {
    height: 250px;
  }
}

@media screen and (max-width: 551px) {
  .header button {
    margin-top: 8em;
    font-size: 14px;
    margin-left: 12em;
  }
  .header {
    height: 190px;
  }
}

@media screen and (max-width: 423px) {
  .header button {
    margin-top: 8em;
    font-size: 12px;
    margin-left: 10em;
  }
  .header {
    height: 180px;
  }
}

@media screen and (max-width: 382px) {
  .header button {
    margin-top: 5em;
    font-size: 12px;
    margin-left: 9em;
  }
  .header {
    height: 150px;
  }
}

@media screen and (max-width: 301px) {
  .header button {
    margin-top: 4em;
    font-size: 8px;
    margin-left: 14em;
  }
  .header {
    height: 120px;
  }
}

.manifesto {
  text-align: center;
  padding: 5em;
  color: #3d9dd9;
}

.manifesto h1 {
  font-size: 4em;
}

.manifesto-header {
  margin-bottom: 5em;
  display : flex;
  align-items: center;
}
.manifesto-padding{
  padding-top: 8.7em;
}
.manifesto-section-one h1,
h1 {
  color: #3d9dd9;
}

.manifesto-text {
  font-size: 1.4em;
}

.manifesto-support {
  margin : 10px;
  display: flex;
  justify-content: center;
}

.manifesto-support h1 {
  text-align: center;
  margin : 15px;
}

.manifesto-support p,
img {
  padding-top: 1em;
  padding-bottom: 1em;
}

.manifesto-support img {
  margin: 1em auto;
}

.manifesto-support-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: aliceblue;
  border-radius: 20px;
  margin: 20px;
}

.manifesto-contact a {
  display: block;
  text-decoration: none;
  margin: 10px;
}

.manifesto-contribute {
  display: flex;
  justify-content : center;
  padding : 30px;
  margin : 0px auto;
}

.manifesto-contribute li {
  line-height: 25px;
}

@media only screen and (max-width: 768px){
  .manifesto{
    padding:0px;
  }
  .manifesto-header {
    margin-bottom: 0;
  }
  .manifesto-header .imgContainer{
    display: none;
  }
}
